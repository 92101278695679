// utils/tabIdentifier.js
const getTabIdentifier = () => {
  let tabIdentifier = sessionStorage.getItem('tabIdentifier');

  if (!tabIdentifier) {
    tabIdentifier = `tab-${Math.random().toString(36).substr(2, 9)}`;
    sessionStorage.setItem('tabIdentifier', tabIdentifier);
  }

  return tabIdentifier;
};

export default getTabIdentifier;
