import React, { useEffect, useState, useRef } from 'react';
import localforageWrapper from '../../utils/localforageWrapper';
import { TrashIcon } from '../ux/icons';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { Tooltip } from 'react-tooltip';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useStyles } from '../ux/style/bookingstyling';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { formatPrice } from 'utils/price-helpers';
import queryString from 'query-string';
import Checkout from 'routes/checkout/Checkout';
import { red } from '@mui/material/colors';

/**
 * BookingDetails Component
 * Renders a vertical filter UI for filtering hotel results.
 *
 * @param {Object} props - Props for the component.
 * @param {Array} props.filtersData - An array of filters data objects to display.
 * @param {Function} props.onFiltersUpdate - Callback function to handle filter updates.
 * @param {Function} props.onClearFiltersAction - Callback function to handle clearing of filters.
 * @param {boolean} props.isBookingDetailsOpen - Flag to control the visibility of the vertical filters.
 * @param {Function} props.onBookedDataUpdate - Callback function to handle the Bookings.
 * @param {Function} props.onclearData - clear all bookingdata
 */
const BookingDetails = (props) => {
  const {
    filtersData,
    onFiltersUpdate,
    onClearFiltersAction,
    isBookingDetailsOpen,
    onBookedDataUpdate,
    checkInDate,
    checkOutDate,
    onclearData,
    onHotelNameChange,
    hotelName,
  } = props;

  debugger;

  const classes = useStyles();
  const bookButtonRef = useRef(null);
  const navigate = useNavigate();
  const [dataSummary, setDataSummary] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalBookedRooms, setTotalBookedRooms] = useState(0);
  const isActiveFilterSelected = () => {
    for (const filterGroup of filtersData) {
      for (const subfilter of filterGroup.filters) {
        if (subfilter.isSelected) {
          return true;
        }
      }
    }
    return false;
  };

  const fetchData = async () => {
    try {
      setDataSummary([]);
      setTotalBookedRooms(0);
      const value = await localforageWrapper.getItem('myData');
      if (Array.isArray(value)) {
        const summary = value.reduce((acc, node) => {
          // Create a unique key for grouping
          const key = `${node.hotelcode}-${node.rtcode}-${node.ratecode}-${node.ocode}`;

          // If the group doesn't exist, create it
          if (!acc[key]) {
            acc[key] = {
              srno: node.srno,
              hotelcode: node.hotelcode,
              rtcode: node.rtcode,
              ratecode: node.ratecode,
              ocode: node.ocode,
              roomtype: node.roomtype,
              adult: 0,
              child: 0,
              adultrate: 0,
              extraadultrate: 0,
              childrate: 0,
              childage: node.childage, // Assuming childage is the same for the same group
              totalroom: 0,
              totalcount: 0, // Initialize totalcount
              totalamount: 0, // Initialize totalcount
              ratedetails: node.ratedetails,
            };
          }

          // Sum the values
          acc[key].adult += node.adult;
          acc[key].child += node.child;
          acc[key].adultrate += node.adultrate;
          acc[key].extraadultrate += node.extraadultrate;
          acc[key].childrate += node.childrate * node.child;
          acc[key].totalroom += node.totalroom;
          acc[key].totalcount += 1; // Increment totalcount
          acc[key].totalamount += node.adultrate + node.childrate * node.child; // Increment totalcount

          return acc;
        }, {});

        // Convert the grouped object back to an array
        const summaryArray = Object.values(summary);

        setDataSummary(summaryArray);
        if (Array.isArray(summaryArray)) {
          // Sum up the totalamount from each object in summaryArray
          const totalAmount = summaryArray.reduce(
            (sum, item) => sum + item.totalamount,
            0
          );
          setTotalAmount(totalAmount);

          const totalRooms = summaryArray.reduce(
            (sum, item) => sum + item.totalcount,
            0
          );
          setTotalBookedRooms(totalRooms);
        }
      }
    } catch (error) {
      console.error('Error retrieving data:', error);
    }
  };

  const deleteNode = async (hotelcode, rtcode, ratecode, ocode) => {
    try {
      const value = await localforageWrapper.getItem('myData');
      let prevData = Array.isArray(value) ? value : [];
      prevData = prevData.filter(
        (node) =>
          node.hotelcode !== hotelcode ||
          node.rtcode !== rtcode ||
          node.ratecode !== ratecode ||
          node.ocode !== ocode
      );

      await localforageWrapper.setItem('myData', prevData);
      console.log('Node deleted:', rtcode);

      // Dispatch custom event to notify other parts of the app
      const event = new CustomEvent('localforageUpdated');
      window.dispatchEvent(event);
    } catch (error) {
      console.error('Error deleting node:', error);
    }
  };

  useEffect(() => {
    // Fetch data initially
    fetchData();

    // Create a function to handle custom events
    const handleStorageChange = async () => {
      await fetchData();
    };

    // Register the custom event handler
    window.addEventListener('localforageUpdated', handleStorageChange);

    // Clean up the custom event handler on component unmount
    return () => {
      window.removeEventListener('localforageUpdated', handleStorageChange);
    };
  }, []);

  const onBookingConfirm = () => {
    /*  if (!dateRange[0].startDate || !dateRange[0].endDate) {
      setErrorMessage('Please select check-in and check-out dates.');
      return;
    }
    const checkIn = format(dateRange[0].startDate, 'dd-MM-yyyy');
    const checkOut = format(dateRange[0].endDate, 'dd-MM-yyyy');
    const queryParams = {
      hotelCode,
      checkIn,
      checkOut,
      guests: selectedGuests.value,
      rooms: selectedRooms.value,
      hotelName: bookingDetails.name.replaceAll(' ', '-'), // url friendly hotel name
    };
 */
    const bookingDetails = {
      hotelCode: 2,
      hotelName: hotelName,
      checkIn: checkInDate,
      checkOut: checkOutDate,
      guests: 3,
      rooms: 2,
      hotelName: hotelName,
      total: totalAmount, // example value
    };

    navigate('/checkout', { state: bookingDetails });
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const handleViewCartClick = () => {
    if (bookButtonRef.current) {
      bookButtonRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className={`booking-details__container shadow-lg border w-full min-w-[300px] z-10 ${
        isBookingDetailsOpen ? '' : ''
      } absolute top-10 left-2 bg-white md:block md:static md:shadow-none `}
      data-testid="vertical-filters"
      style={{ margin: 0, minHeight: '300px' }}
    >
      <div
        className="booking-details__header flex justify-between items-center py-2 border-b-2 border border-gray-300 px-2"
        style={{ backgroundColor: '#f8f8f8' }}
      >
        <span
          style={{
            color: 'black',
            fontWeight: 600,
            fontSize: 14,
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          Booking Details
        </span>
        {dataSummary && dataSummary.length > 100 ? (
          <>
            <Button
              size="small"
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={onclearData}
            >
              Clear
            </Button>
          </>
        ) : null}
      </div>
      <div className="d-flex justify-content-between px-2  p-1 ">
        <span
          style={{
            color: '#1e87f0',
            fontWeight: 400,
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          {hotelName}
        </span>
      </div>
      <div className="d-flex justify-content-between border-b border-gray-300 mb-3 px-2 p-1">
        <div>
          <div style={{ color: '#878787', fontSize: '12px' }}>Check-In</div>
          <div
            style={{
              color: 'black',
              fontWeight: 600,
              fontSize: 14,
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            {checkInDate}
          </div>
        </div>
        <div>
          <div style={{ color: '#878787', fontSize: '12px' }}>Check-Out</div>
          <div
            style={{
              color: 'black',
              fontWeight: 600,
              fontSize: 14,
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            {checkOutDate}
          </div>
        </div>
      </div>
      {dataSummary && dataSummary.length > 0 ? (
        <>
          {dataSummary.map((node) => (
            <div className="border-b" key={node.srno} style={{ margin: 0 }}>
              <div className="flex justify-between items-center px-2">
                <span
                  style={{
                    color: 'black',
                    fontWeight: 600,
                    fontSize: 14,
                    fontFamily: 'Poppins, sans-serif',
                  }}
                >
                  <p>{node.roomtype}</p>
                </span>
                <button
                  className="text-sm inline-flex items-center px-2 py-1 border border-red-300 font-medium rounded text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  onClick={() =>
                    deleteNode(
                      node.hotelcode,
                      node.rtcode,
                      node.ratecode,
                      node.ocode
                    )
                  }
                  data-tooltip-id="delete-tooltip"
                  data-tooltip-content="Delete"
                >
                  <TrashIcon width={'22px'} />
                  <Tooltip id="delete-tooltip" />
                </button>
              </div>

              <div className="px-2">
                <div className="d-flex justify-content-between mb-3 px-2">
                  <div>
                    <div style={{ color: '#878787', fontSize: '12px' }}>
                      Rooms
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontWeight: 500,
                        fontSize: 14,
                        fontFamily: 'Poppins, sans-serif',
                      }}
                    >
                      {node.totalroom}
                    </div>
                  </div>
                  <div>
                    <div style={{ color: '#878787', fontSize: '12px' }}>
                      Adult
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontWeight: 500,
                        fontSize: 14,
                        fontFamily: 'Poppins, sans-serif',
                      }}
                    >
                      {node.adult}
                    </div>
                  </div>
                  <div>
                    <div style={{ color: '#878787', fontSize: '12px' }}>
                      Child
                    </div>
                    <div
                      style={{
                        color: 'black',
                        fontWeight: 500,
                        fontSize: 14,
                        fontFamily: 'Poppins, sans-serif',
                      }}
                    >
                      {node.child}
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p
                      style={{
                        color: 'black',
                        fontWeight: 500,
                        fontSize: 14,
                        marginTop: 20,
                        fontFamily: 'Poppins, sans-serif',
                        marginRight: 5,
                      }}
                    >
                      ₹ {node.totalamount}
                    </p>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            color="inherit"
                            style={{
                              color: 'black',
                              fontWeight: 600,
                              fontSize: 10,
                              fontFamily: 'Poppins, sans-serif',
                            }}
                          >
                            Rate Details
                          </Typography>
                          {node.ratedetails && node.ratedetails.length > 0 && (
                            <div className="flex">
                              {JSON.parse(node.ratedetails).map(
                                (item, index) => (
                                  <div key={index} className="border p-1">
                                    <div className="flex justify-between items-center">
                                      <span
                                        style={{
                                          color: 'black',
                                          fontWeight: 400,
                                          fontSize: 10,
                                          fontFamily: 'Poppins, sans-serif',
                                        }}
                                      >
                                        {item.AvlDate}
                                      </span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                      <span
                                        style={{
                                          color: 'black',
                                          fontWeight: 600,
                                          fontSize: 10,
                                          fontFamily: 'Poppins, sans-serif',
                                          marginRight: 5,
                                        }}
                                      >
                                        ₹ {item.RoomCharge}
                                      </span>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </React.Fragment>
                      }
                    >
                      <InfoOutlinedIcon
                        style={{
                          fontSize: 'default',
                          color: 'lightblue',
                          fontWeight: 100,
                        }}
                      />
                    </HtmlTooltip>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="d-flex border-b justify-content-between m-2 px-2">
            <span>Total</span>
            <span
              style={{
                color: 'black',
                fontWeight: 600,
                fontSize: 16,
                fontFamily: 'Poppins, sans-serif',
              }}
            >
              ₹ {totalAmount}
            </span>
          </div>
          <div className="text-center my-4">
            <Button
              ref={bookButtonRef}
              className={classes.bookbutton}
              variant="outlined"
              endIcon={<SendIcon />}
              onClick={onBookingConfirm}
            >
              Book Now
            </Button>
          </div>
          <div
            className="text-center my-4 d-lg-none d-md-none"
            style={{ height: 20 }}
          ></div>
        </>
      ) : (
        <div
          className="px-2"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Center horizontally
            justifyContent: 'center', // Center vertically
            textAlign: 'center', // Center text horizontally
            height: '100%', // Adjust height as needed
          }}
        >
          <img
            src="/images/nodata.png"
            alt="No rooms available"
            style={{ width: '100px', height: '100px', marginBottom: '10px' }}
          />
          No Room Selected.....!
        </div>
      )}

      <>
        {totalBookedRooms > 0 ? (
          <>
            <div
              className="col-lg-5 col-md-5 col-xs-5 fixed-bottom d-lg-none d-md-none"
              id="BottomMenuBar"
              style={{
                display: 'block',
                backgroundColor: '#4caf50',
                color: 'white',
                // position: "fixed",
                bottom: '0',
                zIndex: '9',
              }}
            >
              <div>
                <div className="row">
                  <div
                    className="col-sm-12"
                    style={{
                      paddingRight: '0px',
                      maxHeight: '50px',
                      backgroundColor: 'rgb(233, 233, 233)',
                    }}
                  >
                    <div className="form-group">
                      <div
                        style={{
                          width: '50%',
                          float: 'left',
                          background: 'rgb(233 233 233)',
                          color: 'black',
                        }}
                      >
                        <h4
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: 5,
                          }}
                          onClick={handleViewCartClick}
                        >
                          <InfoOutlinedIcon
                            style={{
                              fontSize: 'default',
                              color: 'green',
                              fontWeight: 100,
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '12px',
                              }}
                            >
                              Rooms
                            </span>
                            <span
                              style={{
                                fontSize: '20px',
                              }}
                            >
                              {totalBookedRooms}
                            </span>
                          </div>
                          <span
                            style={{
                              paddingTop: '5px',
                              fontSize: '20px',
                            }}
                          >
                            ₹ {formatPrice(totalAmount)}
                          </span>
                        </h4>
                      </div>
                      <div
                        style={{
                          width: '50%',
                          float: 'right',
                          backgroundColor: 'rgb(76 175 80)',
                        }}
                      >
                        <button
                          id="viewcart"
                          className="Projectbackground-colorColor"
                          style={{
                            width: '100%',
                            height: '50px',
                            fontSize: '16px',
                            display: 'flex', // To align items properly
                            justifyContent: 'center', // Center the content horizontally
                            alignItems: 'center', // Center the content vertically
                          }}
                          onClick={onBookingConfirm}
                        >
                          Book Now
                          <SendIcon style={{ marginLeft: '8px' }} />
                        </button>
                      </div>
                    </div>{' '}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-xs-4 fixed-bottom"></div>
          </>
        ) : (
          <div></div>
        )}
      </>
    </div>
  );
};

export default BookingDetails;
