const EmptyRoomState = () => (
  <div
    className="text-center  min-h-96"
    style={{
      backgroundColor: 'white',
      paddingTop: 50,
    }}
  >
    <h3 className="text-lg text-gray-800 font-semibold">
      No Rooms Available....!
    </h3>
    <p className="text-gray-500">Please search another dates.....!</p>
  </div>
);

export default EmptyRoomState;
