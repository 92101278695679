import React, { useEffect, useState, forwardRef } from 'react';
import { styled } from '@mui/system';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
} from '@mui/base/Unstable_NumberInput';

const NumberInput = forwardRef(function CustomNumberInput(
  { min = 0, max = 10, label, onChange, ...props },
  ref
) {
  const [value, setValue] = useState(1);

  useEffect(() => {
    if (value === null || isNaN(Number(value)) || value < min) {
      setValue(min.toString());
    } else if (value > max) {
      setValue(max.toString());
    }
  }, [value, min, max]);

  const handleValueChange = (newValue) => {
    if (newValue === null || isNaN(Number(newValue)) || newValue < min) {
      setValue(min.toString());
    } else if (newValue > max) {
      setValue(max.toString());
    } else {
      setValue(newValue.toString());
    }
    // Call the onChange prop passed from parent component
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleIncrement = () => {
    const newValue = parseInt(value) + 1;
    handleValueChange(newValue);
  };

  const handleDecrement = () => {
    const newValue = parseInt(value) - 1;
    handleValueChange(newValue);
  };

  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: (inputProps) => (
          <StyledInput
            {...inputProps}
            value={value}
            readOnly
            tabIndex={-1}
            ref={ref}
          />
        ),
        decrementButton: (buttonProps) => (
          <StyledButton
            {...buttonProps}
            onClick={handleDecrement}
            className="decrement"
          >
            <RemoveIcon fontSize="small" />
          </StyledButton>
        ),
        incrementButton: (buttonProps) => (
          <StyledButton
            {...buttonProps}
            onClick={handleIncrement}
            className="increment"
          >
            <AddIcon fontSize="small" />
          </StyledButton>
        ),
      }}
      slotProps={{
        input: {
          readOnly: true,
          tabIndex: -1,
          value: value,
        },
      }}
      value={value}
      onChange={handleValueChange}
      {...props}
    />
  );
});

const blue = {
  100: '#daecff',
  200: '#b6daff',
  300: '#66b2ff',
  400: '#3399ff',
  500: '#007fff',
  600: '#0072e5',
  700: '#0059B2',
  800: '#004c99',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const StyledInputRoot = styled('div')(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`
);

const StyledInput = styled('input')(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border-top: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  border-bottom: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  border-left: none;
  border-right: none;
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
  };
  border-radius: 0px;
  margin: 2px 0px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 3rem;
  text-align: center;

  &:hover {
    // border-color: ${blue[400]};
  }

  &:focus {
    // border-color: ${blue[400]};
    // box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
  }

  &:focus-visible {
    outline: 0;
  }
`
);

const StyledButton = styled('button')(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 2px;
  border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  width: 32px;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
    background: ${theme.palette.mode === 'dark' ? blue[700] : blue[500]};
    border-color: ${theme.palette.mode === 'dark' ? blue[500] : blue[400]};
    color: ${grey[50]};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 2;
  }

  &.decrement {
    order: 0;
  }
`
);

export default NumberInput;
