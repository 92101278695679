import React, { useState, useEffect } from 'react';
import { faStar, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { formatPrice } from 'utils/price-helpers';
import RoomSelection from './RoomSelection';
import { PlusSquareIcon, MinusSquareIcon, TrashIcon } from '../ux/icons';
import Button from '@mui/material/Button';
import { useStyles } from '../ux/style/bookingstyling';
import localforageWrapper from '../../utils/localforageWrapper';
import Item from 'antd/es/list/Item';
/**
 * RoomViewCard Component
 * Renders a card view for a hotel, displaying its image, title, subtitle, benefits, price, and ratings.
 * Provides a 'Book now' button to navigate to the hotel's detailed view.
 *
 * @param {Object} props - Props for the component.
 * @param {string} props.id - The unique code of the hotel.
 * @param {Object} props.image - The image object for the hotel, containing the URL and alt text.
 * @param {string} props.title - The title of the hotel.
 * @param {string} props.subtitle - The subtitle or a short description of the hotel.
 * @param {Array} props.benefits - A list of benefits or features offered by the hotel.
 * @param {string} props.price - The price information for the hotel.
 * @param {number} props.ratings - The ratings of the hotel.
 * @param {Function} props.onBookedDataUpdate - Callback function to handle the Bookings.
 */

let TotalItemCount = 0;
let TotalItemPrice = 0;
let GroupList = '';
let categoryHeading = '';
let cartItems = '';
let product = '';

const RoomViewCard = (props) => {
  const {
    hotelcode: hotelcode,
    rtcode: rtcode,
    ratecode: ratecode,
    ocode: ocode,
    image,
    title,
    subtitle,
    benefits,
    price,
    ratings,
    roomid,
    maxadult,
    maxchild,
    rackrate,
    rateperday,
    adultrate,
    childrate,
    onBookedDataUpdate,
    checkInDate,
    checkOutDate,
    adultlabel,
    childlabel,
    childagelists,
  } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const onBookNowClick = () => {
    //navigate(`/hotel/${hotelcode}`);
  };
  const [numRoomCount, setRoomCount] = useState(0);
  const [bookedData, setBookedData] = useState([]);

  useEffect(() => {
    const handleStorageChange = async () => {
      // Load data from storage when the component mounts

      const value = await localforageWrapper.getItem('myData');
      const data = value === null ? [] : value;
      if (Array.isArray(data)) {
        setBookedData(
          data.filter(
            (item) =>
              item.hotelcode === hotelcode &&
              item.rtcode === rtcode &&
              item.ratecode === ratecode &&
              item.ocode === ocode
          )
        );
      }
      /* if (value) {
        setBookedData(value);
      } */
      // Sum totalroom based on hotelcode and rtcode

      const filteredData = data.filter(
        (item) =>
          item.hotelcode === hotelcode &&
          item.rtcode === rtcode &&
          item.ratecode === ratecode &&
          item.ocode === ocode
      );

      const totalRoomSum = filteredData.reduce(
        (accumulator, item) => accumulator + parseInt(item.totalroom, 10),
        0
      );

      // Set RoomCount state to the calculated sum
      setRoomCount(totalRoomSum);
    };

    // Register the custom event handler
    window.addEventListener('localforageUpdated', handleStorageChange);

    // Clean up the custom event handler on component unmount
    return () => {
      window.removeEventListener('localforageUpdated', handleStorageChange);
    };
  }, [hotelcode, rtcode, ratecode]);

  const updateOrAddNode = async (newNode) => {
    try {
      const value = await localforageWrapper.getItem('myData');
      let prevData = Array.isArray(value) ? value : [];

      const nodeIndex = prevData.findIndex(
        (node) =>
          node.key === newNode.key &&
          node.hotelcode === newNode.hotelcode &&
          node.rtcode === newNode.rtcode &&
          node.ratecode === newNode.ratecode &&
          node.ocode === newNode.ocode
      );

      if (nodeIndex !== -1) {
        // Update existing node
        prevData[nodeIndex] = newNode;
      } else {
        // Add new node
        prevData = [...prevData, newNode];
      }

      await localforageWrapper.setItem('myData', prevData);
      console.log('Data saved:', prevData);
      // Dispatch custom event to notify other parts of the app
      const event = new CustomEvent('localforageUpdated');
      window.dispatchEvent(event);
    } catch (error) {
      console.error('Error updating/adding node:', error);
    }
  };

  const IncrementItem = async () => {
    if (numRoomCount === props.availableRooms) {
      return;
    }
    const value = await localforageWrapper.getItem('myData');
    let prevData = Array.isArray(value) ? value : [];
    let keyno = '';
    keyno = prevData.length + 1;

    const newNode = {
      key:
        props.hotelcode.toString() +
        props.rtcode.toString() +
        props.ratecode.toString() +
        props.ocode.toString() +
        keyno,
      srno: prevData.length + 1,
      hotelcode: props.hotelcode,
      rtcode: props.rtcode,
      ratecode: props.ratecode,
      ocode: props.ocode,
      roomtype: props.title,
      adult: parseInt(1, 10),
      child: parseInt(0, 10),
      maxadult: props.maxadult,
      maxchild: props.maxchild,
      adultrate: props.adultrate,
      extraadult: props.extraadult,
      childrate: props.childrate,
      taxflag: props.taxflag,
      taxrate: props.taxrate,
      childage: [],
      totalroom: 1,
      checkin: checkInDate,
      checkout: checkOutDate,
      ratedetails: props.ratedetails,
      adultlabel: props.adultlabel,
      childlabel: props.childlabel,
      childagelists: props.childagelists,
    };

    setRoomCount((count) => count + 1);
    updateOrAddNode(newNode);
  };

  const DecreaseItem = async () => {
    const value = await localforageWrapper.getItem('myData');
    let prevData = Array.isArray(value) ? value : [];

    // Find the index of the item to remove
    const itemIndex = prevData.findIndex(
      (item) =>
        item.hotelcode === props.hotelcode &&
        item.rtcode === props.rtcode &&
        item.ratecode === props.ratecode &&
        item.ocode === props.ocode
    );

    if (itemIndex !== -1) {
      // Remove the item at the found index
      prevData.splice(itemIndex, 1);

      await localforageWrapper.setItem('myData', prevData);
      console.log('Data updated:', prevData);

      // Dispatch custom event to notify other parts of the app
      const event = new CustomEvent('localforageUpdated');
      window.dispatchEvent(event);
    }

    setRoomCount((count) => (count > 0 ? count - 1 : 0));
  };

  return (
    <div className="card border p-1 flex flex-row flex-wrap w-full mb-2.5 ">
      <div className="flex flex-col gap-y-2 w-full sm:w-2/6 p-1">
        <div className="cursor-pointer">
          <Link
            // to={`/hotel/${hotelcode}`}
            className="block text-slate-700 hover:text-brand transition-colors duration-300"
          >
            <img
              src={image.imageUrl}
              alt={image.accessibleText}
              className="md:w-[220px] md:h-[140px]"
            />
          </Link>
        </div>
      </div>
      <div className="flex flex-col gap-y-2 w-full sm:w-4/6 p-1 ">
        <div className="flex flex-row w-full border-none">
          <div className="flex flex-col gap-y-2 w-full sm:w-4/6 p-0 ">
            <div>
              <span
                style={{
                  color: '#1e87f0',
                  fontWeight: 600,
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
                {title}
              </span>
              <div
                style={{
                  color: 'black',
                  fontWeight: 400,
                  fontSize: 12,
                  fontFamily: 'Poppins, sans-serif',
                }}
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-y-2 w-full sm:w-2/6 p-1">
            <div className="flex flex-col ml-0 md:ml-auto justify-between border-l-0 md:border-l-0 items-stretch pl-0 md:pl-4">
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <span
                  style={{
                    color: 'blue',
                    fontWeight: 400,
                    fontSize: 12,
                    textAlign: 'right',
                    fontFamily: 'Poppins, sans-serif',
                  }}
                >
                  ₹
                </span>
                <span
                  style={{
                    color: 'blue',
                    fontWeight: 400,
                    fontSize: 12,
                    textAlign: 'right',
                    fontFamily: 'Poppins, sans-serif',
                    textDecoration: 'line-through',
                  }}
                >
                  {formatPrice(price)}
                </span>
                <span
                  style={{
                    color: 'black',
                    fontWeight: 300,
                    fontSize: 10,
                    textAlign: 'right',
                    fontFamily: 'Poppins, sans-serif',
                    display: 'block',
                  }}
                >
                  1 Room Per Night
                </span>
                <span
                  style={{
                    color: 'black',
                    fontWeight: 600,
                    fontSize: 18,
                    textAlign: 'right',
                    fontFamily: 'Poppins, sans-serif',
                  }}
                >
                  ₹ {formatPrice(rateperday)}
                </span>
              </div>
            </div>
            <div className="room-add">
              <div className="flex justify-end">
                {props.availableRooms > 0 ? (
                  <span
                    style={{
                      color: 'green',
                      fontWeight: 600,
                      fontSize: 10,
                      fontFamily: 'Poppins, sans-serif',
                    }}
                  >
                    {props.availableRooms} Room(s) Available
                  </span>
                ) : (
                  <div></div>
                )}
              </div>

              {numRoomCount > 0 && (
                <div
                  style={{
                    float: 'right',
                    padding: '0px',
                    boxShadow: 'none',
                    border: '0px',
                    marginTop: '0px',
                    textAlign: 'center',
                    position: 'relative',
                  }}
                >
                  <div style={{ padding: '0px', margin: '0px', float: 'left' }}>
                    {numRoomCount > 1 && (
                      <button
                        className="Projectbackground-colorColor"
                        onClick={(e) => DecreaseItem(numRoomCount)}
                        style={{
                          borderTopRightRadius: '0px',
                          borderBottomRightRadius: '0px',
                          width: '100%',
                        }}
                      >
                        <MinusSquareIcon width={'33px'} />
                      </button>
                    )}

                    {numRoomCount === 1 && (
                      <button
                        className="Projectbackground-colorColor btn btn-danger btn-sm mb-1"
                        onClick={(e) => DecreaseItem(numRoomCount)}
                        style={{ width: '100%' }}
                      >
                        <TrashIcon width={'22px'} />
                      </button>
                    )}
                  </div>
                  <div
                    style={{
                      padding: '0px',
                      height: '33px',
                      width: '33px',
                      border: '1px solid rgb(233 230 230)',
                      background: 'white',
                      textAlign: 'center',
                      float: 'left',
                      marginTop: '0px',
                      position: 'relative',
                    }}
                  >
                    <h2
                      style={{
                        margin: '0px',
                        fontSize: '16px',
                        padding: '7px',
                        textAlign: 'center',
                      }}
                    >
                      {numRoomCount}
                      {/* {count} */}
                    </h2>
                  </div>

                  <div
                    style={{
                      padding: '0px',
                      margin: '0px',
                      borderRadius: '0px',
                      float: 'left',
                    }}
                  >
                    <button
                      id={product.id + 'e'}
                      onClick={(e) => IncrementItem(numRoomCount)}
                      // onClick={()=>setPopUp(true)}
                      style={{
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '0px',
                        width: '100%',
                      }}
                    >
                      <PlusSquareIcon width={'33px'} />
                    </button>
                  </div>
                </div>
              )}
              {numRoomCount === 0 && (
                <div
                  style={{
                    marginTop: '0px',
                    float: 'right',
                    padding: '0px',
                    borderRadius: '0px',
                    width: '100%',
                    textAlign: 'center',
                    zIndex: '999',
                  }}
                >
                  <Button
                    className={classes.addbutton}
                    style={{ fontSize: 12 }}
                    variant="outlined"
                    onClick={(e) => IncrementItem(product)}
                  >
                    Add Room
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row flex-wrap w-full">
          {/* {Array.from({ length: numRoomCount }).map((_, index) => (
            <RoomSelection
              key={index}
              index={index}
              hotelcode={hotelcode}
              rtcode={rtcode}
              ratecode={ratecode}
            />
          ))} */}
          {bookedData.map((item, index) => (
            <RoomSelection
              nodekey={item.key}
              srno={item.srno}
              index={item.srno}
              hotelcode={item.hotelcode}
              rtcode={item.rtcode}
              ratecode={item.ratecode}
              ocode={item.ocode}
              maxadult={item.maxadult}
              maxchild={item.maxchild}
              adultrate={item.adultrate}
              extraadult={item.extraadult}
              childrate={item.childrate}
              childlabel={item.childlabel}
              adultlabel={item.adultlabel}
              childagelists={item.childagelists}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default RoomViewCard;
