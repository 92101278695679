import RoomViewCard from 'components/room-view-card/RoomViewCard';
import VerticalFilters from 'components/vertical-filters/VerticalFilters';
import BookingDetails from 'components/booking-detail/BookingDetails';
import HotelViewCardSkeleton from 'components/hotel-view-card-skeleton/HotelViewCardSkeleton';
import VerticalFiltersSkeleton from 'components/vertical-filters-skeleton/VerticalFiltersSkeleton';
import EmptyRoomState from 'components/empty-rooms-state/EmptyRoomsState';
import { useRef, useState } from 'react';
import useOutsideClickHandler from 'hooks/useOutsideClickHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { Margin } from '@mui/icons-material';

/**
 * ResultsContainer Component
 * Renders a container that displays hotel results, including hotel cards and filters.
 * It supports toggling of vertical filters and displays skeletons or empty states based on loading or data availability.
 *
 * @param {Object} props - Props for the component.
 * @param {Object} props.hotelsResults - Object containing hotel results data and loading state.
 * @param {boolean} props.enableFilters - Flag to enable or disable the filter feature.
 * @param {Array} props.filtersData - Array of filter data objects for the vertical filters.
 * @param {Array} props.selectedFiltersState - Array of selected filter states.
 * @param {Function} props.onFiltersUpdate - Callback function to handle filter updates.
 * @param {Function} props.onClearFiltersAction - Callback function to handle the action of clearing filters.
 * @param {Function} props.onBookedDataUpdate - Callback function to handle the Bookings.
 * @param {Array} props.sortingFilterOptions - Array of sorting filter options.
 * @param {Object} props.sortByFilterValue - Object containing the selected sorting filter value.
 * @param {Function} props.onSortingFilterChange - Callback function to handle sorting filter changes.
 * @param {Function} props.checkInDate - Checkin Date
 * @param {Function} props.checkOutDate - Check-Out Date
 *  @param {Function} props.onClearData - Check-Out Date
 */
const BookingContainer = (props) => {
  const {
    hotelsResults,
    enableFilters,
    filtersData,
    selectedFiltersState,
    onFiltersUpdate,
    onClearFiltersAction,
    sortingFilterOptions,
    sortByFilterValue,
    onSortingFilterChange,
    onBookedDataUpdate,
    startDate,
    endDate,
    onclearData,
    hotelName,
  } = props;

  // Check if sorting filter is visible
  const isSortingFilterVisible =
    sortingFilterOptions && sortingFilterOptions.length > 0;

  const [isVerticalFiltersOpen, setIsVerticalFiltersOpen] = useState(false);

  const wrapperRef = useRef();
  const buttonRef = useRef();

  /*  useOutsideClickHandler(wrapperRef, (event) => {
    if (!buttonRef.current.contains(event.target)) {
      setIsVerticalFiltersOpen(false);
    }
  }); */
  const toggleVerticalFiltersAction = () => {
    // Toggle based on the current state
    setIsVerticalFiltersOpen((prevState) => !prevState);
  };

  return (
    <div className="relative">
      <div
        className="flex flex-col md:flex-row gap-y-2 w-full"
        style={{
          backgroundColor: 'whitesmoke',
          paddingTop: 10,
          paddingRight: 15,
        }}
      >
        <div
          className="room__container w-full md:w-7/10 mx-2 md:mx-0"
          style={{ marginRight: '5px' }}
        >
          {hotelsResults.isLoading ? (
            Array.from({ length: 5 }, (_, index) => (
              <HotelViewCardSkeleton key={index} />
            ))
          ) : Array.isArray(hotelsResults.data) &&
            hotelsResults.data.length > 0 ? (
            hotelsResults.data.map((hotel, index) => (
              <RoomViewCard
                key={hotel.HotelCode + index}
                hotelcode={hotel.HotelCode}
                title={hotel.RoomTypeName}
                image={{
                  imageUrl: '/images/hotels/481481762/481481762.jpg',
                  accessibleText: 'hotel room',
                }}
                subtitle={hotel.RoomDetail}
                // benefits={hotel.benefits}
                // ratings={hotel.ratings}
                price={hotel.RackRate}
                rtcode={hotel.RTCode}
                ratecode={hotel.PCode}
                ocode={hotel.OCode}
                maxadult={hotel.AdultTotal}
                maxchild={hotel.ChildTotal}
                rackrate={hotel.RackRate}
                rateperday={hotel.RoomCharge}
                adultrate={hotel.TotalCharge}
                extraadult={hotel.ExtraBed}
                childrate={hotel.ExtraBedChild}
                taxflag={hotel.TaxFlag}
                taxrate={hotel.TaxRate}
                onBookedDataUpdate={onBookedDataUpdate}
                checkInDate={startDate}
                checkOutDate={endDate}
                availableRooms={hotel.ARooms}
                ratedetails={hotel.RateDetail}
                adultlabel={hotel.AdultLabel}
                childlabel={hotel.ChildLabel}
                childagelists={hotel.ChildAges}
              />
            ))
          ) : (
            <EmptyRoomState />
          )}
        </div>
        <div className="relative">
          <div className="flex flex-col md:flex-row gap-y-2 w-full">
            <div className="selected_container w-full md:w-3/10 flex gap-x-0 md:gap-x-4 items-start mx-2">
              {enableFilters && selectedFiltersState.length > 0 && (
                <div ref={wrapperRef}>
                  <BookingDetails
                    filtersData={selectedFiltersState}
                    onFiltersUpdate={onFiltersUpdate}
                    onClearFiltersAction={onClearFiltersAction}
                    isVerticalFiltersOpen={isVerticalFiltersOpen}
                    onBookedDataUpdate={onBookedDataUpdate}
                    checkInDate={startDate}
                    checkOutDate={endDate}
                    onclearData={onclearData}
                    hotelName={hotelName}
                  />
                </div>
              )}
              {enableFilters && filtersData.isLoading && (
                <VerticalFiltersSkeleton />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingContainer;
