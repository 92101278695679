import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import localforageWrapper from '../../utils/localforageWrapper';

const RoomSelection = ({
  nodekey,
  srno,
  index,
  hotelcode,
  rtcode,
  ratecode,
  ocode,
  maxadult,
  maxchild,
  adultrate,
  extraadult,
  childrate,
  childlabel,
  adultlabel,
  childagelists,
}) => {
  debugger;

  const agelist = JSON.parse(childagelists);
  // Check if agelist is an array of arrays and initialize childAgeValues
  const defaultAgeValue =
    Array.isArray(agelist) && agelist.length > 0
      ? agelist[0].toString() // Only get the first element
      : '0';
  console.log(defaultAgeValue);

  const [childAgeValues, setChildAgeValues] = useState([defaultAgeValue]); // Default value
  const [adult, setAdult] = useState('1');
  const [child, setChild] = useState('0');
  const [childAges, setChildAges] = useState([]);

  useEffect(() => {
    // Retrieve the data from localforage when the component mounts
    const fetchData = async () => {
      try {
        const value = await localforageWrapper.getItem('myData');
        if (Array.isArray(value)) {
          debugger;
          const node = value.find(
            (node) =>
              node.key === nodekey &&
              node.hotelcode === hotelcode &&
              node.rtcode === rtcode &&
              node.ratecode === ratecode &&
              node.ocode === ocode
          );
          if (node) {
            setAdult(node.adult.toString());
            setChild(node.child.toString());
            setChildAgeValues(
              node.childage ? Object.values(node.childage) : [defaultAgeValue]
            );
          }
        }
      } catch (error) {
        console.error('Error retrieving data:', error);
      }
    };

    fetchData();
  }, [nodekey, srno, index, hotelcode, rtcode, ratecode]);

  useEffect(() => {
    // Update the number of child age selects based on the child count
    debugger;
    const ages = Array.from({ length: parseInt(child, 10) }, (_, i) => i + 1);
    setChildAges(ages);
    setChildAgeValues(Array(parseInt(child, 10)).fill(defaultAgeValue));
  }, [child]);

  const updateOrAddNode = async (newNode) => {
    try {
      const value = await localforageWrapper.getItem('myData');
      let prevData = Array.isArray(value) ? value : [];

      const nodeIndex = prevData.findIndex(
        (node) =>
          node.key === nodekey &&
          node.hotelcode === newNode.hotelcode &&
          node.rtcode === newNode.rtcode &&
          node.ratecode === newNode.ratecode &&
          node.ocode === newNode.ocode
      );

      if (nodeIndex !== -1) {
        // Update existing node
        prevData[nodeIndex].adult = newNode.adult;
        prevData[nodeIndex].child = newNode.child;
        prevData[nodeIndex].childage = newNode.childage;
      } else {
        // Add new node
        // not required
        //prevData = [...prevData, newNode];
      }

      await localforageWrapper.setItem('myData', prevData);
      console.log('Data saved:', prevData);

      // Dispatch custom event to notify other parts of the app
      const event = new CustomEvent('localforageUpdated');
      window.dispatchEvent(event);
    } catch (error) {
      console.error('Error updating/adding node:', error);
    }
  };

  const handleChange = (event, type, idx = null) => {
    let updatedAdult = adult;
    let updatedChild = child;
    let updatedChildAgeValues = [...childAgeValues];

    if (type === 'adult') {
      updatedAdult = event.target.value;
      setAdult(updatedAdult);
    } else if (type === 'child') {
      updatedChild = event.target.value;
      setChild(updatedChild);
    } else if (type === 'childAge') {
      updatedChildAgeValues[idx] = event.target.value;
      setChildAgeValues(updatedChildAgeValues);
    }

    // Create newNode with the current values
    const newNode = {
      key: nodekey,
      srno: srno,
      hotelcode: hotelcode,
      rtcode: rtcode,
      ratecode: ratecode,
      ocode: ocode,
      adult: parseInt(updatedAdult, 10),
      child: parseInt(updatedChild, 10),
      adultrate: 1000, // Placeholder value, you may need to replace this
      childage: updatedChildAgeValues.reduce((acc, age, idx) => {
        acc[idx + 1] = age;
        return acc;
      }, {}),
      totalroom: 1, // Placeholder value, you may need to replace this
    };

    updateOrAddNode(newNode);
  };

  return (
    <div
      className="container"
      style={{
        background: 'white',
        borderBottom: '1px solid rgb(245 241 241)',
        float: 'left',
      }}
    >
      <div className="row" style={{ marginBottom: 10 }}>
        <div className="flex flex-col gap-y-2 sm:w-1/7 p-2">
          <span>Room {srno}</span>
        </div>
        <div className="flex flex-col gap-y-2 sm:w-2/7 p-2">
          <FormControl
            sx={{ m: 1, minWidth: 80, maxWidth: 100, height: '40px' }}
          >
            <InputLabel id="select-adult-label">Adult</InputLabel>
            <Select
              labelId="select-adult-label"
              id="select-adult"
              value={adult}
              label="Adult"
              onChange={(event) => handleChange(event, 'adult')}
              sx={{
                height: '40px',
                lineHeight: '40px',
                paddingTop: '0px',
                paddingBottom: '0px',
              }}
            >
              {Array.from({ length: maxadult }, (_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{adultlabel}</FormHelperText>
          </FormControl>
        </div>
        <div className="flex flex-col gap-y-2 sm:w-2/7 p-2">
          <FormControl
            sx={{ m: 1, minWidth: 80, maxWidth: 100, height: '40px' }}
          >
            <InputLabel id="select-child-label">Child</InputLabel>
            <Select
              labelId="select-child-label"
              id="select-child"
              value={child}
              label="Child"
              onChange={(event) => handleChange(event, 'child')}
              sx={{
                height: '40px',
                lineHeight: '40px',
                paddingTop: '0px',
                paddingBottom: '0px',
              }}
            >
              <MenuItem value="0">
                <em>0</em>
              </MenuItem>
              {Array.from({ length: maxchild }, (_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{childlabel}</FormHelperText>
          </FormControl>
        </div>
        {childAges.map((age, idx) => (
          <div key={idx} className="flex flex-col gap-y-2 sm:w-1/7 p-2">
            <FormControl
              sx={{
                m: 1,
                minWidth: 100,
                maxWidth: 120,
                height: '40px',
              }}
            >
              <InputLabel id={`select-age-label-${idx}`}>
                Child {idx + 1} Age
              </InputLabel>
              <Select
                labelId={`select-age-label-${idx}`}
                id={`select-age-${idx}`}
                // value={childAgeValues[idx]}
                value={childAgeValues[idx] || defaultAgeValue}
                label={`Child Age ${age}`}
                onChange={(event) => handleChange(event, 'childAge', idx)}
                sx={{
                  height: '40px',
                  lineHeight: '40px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
              >
                {agelist.map((age) => (
                  <MenuItem key={age} value={age}>
                    {age}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RoomSelection;
