import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    transform: 'translate(-50%)',
  },
  searchbutton: {
    height: 40,
    maxWidth: '100%',
    width: '100%',
    minWidth: 120,
    padding: 5,
    border: '1px solid', // Define the border style
    borderColor: theme.palette.primary.main, // Use theme color
    color: theme.palette.primary.main, // Use theme color for text
    backgroundColor: 'transparent', // Transparent background
    borderRadius: theme.shape.borderRadius, // Use theme's border radius
    '&:hover': {
      backgroundColor: theme.palette.action.hover, // Define hover state
    },
  },
  addbutton: {
    float: 'right',
    width: 105,
    maxWidth: 105,
    height: '38px',
    padding: 0,
    fontSize: '10px', // Set font size to 10px
    textAlign: 'center',
    border: '1px solid', // Define the border style
    borderColor: theme.palette.primary.main, // Use theme color
    color: theme.palette.primary.main, // Use theme color for text
    backgroundColor: 'transparent', // Transparent background
    borderRadius: theme.shape.borderRadius, // Use theme's border radius
    '&:hover': {
      backgroundColor: theme.palette.action.hover, // Define hover state
    },
  },
  bookbutton: {
    height: 40,
    maxWidth: '90%',
    width: '90%',
    border: '1px solid', // Define the border style
    borderColor: theme.palette.primary.main, // Use theme color
    color: theme.palette.primary.main, // Use theme color for text
    backgroundColor: 'transparent', // Transparent background
    borderRadius: theme.shape.borderRadius, // Use theme's border radius
    '&:hover': {
      backgroundColor: theme.palette.action.hover, // Define hover state
    },
  },

  paybutton: {
    height: 40,
    maxWidth: '90%',
    width: '90%',
    border: '1px solid', // Define the border style
    borderColor: theme.palette.primary.main, // Use theme color
    color: theme.palette.primary.main, // Use theme color for text
    backgroundColor: 'transparent', // Transparent background
    borderRadius: theme.shape.borderRadius, // Use theme's border radius
    '&:hover': {
      backgroundColor: theme.palette.action.hover, // Define hover state
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '60%',
  },
  form: {
    width: '80%',
  },
}));
