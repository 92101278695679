import HeroCover from './components/hero-cover/HeroCover';
import PopularLocations from './components/popular-locations/popular-locations';
import { networkAdapter } from 'services/NetworkAdapter';
import axios from 'axios';
// import { fetchHotels } from 'services/main';
import isEmpty from 'utils/helpers';
import { useState, useEffect, useCallback } from 'react';
import { MAX_GUESTS_INPUT_VALUE } from 'utils/constants';
import ResultsContainer from 'components/results-container/ResultsContainer';
import { formatDate } from 'utils/date-helpers';
import { useNavigate } from 'react-router-dom';
import _debounce from 'lodash/debounce';
// const apiData = new APIData();
/**
 * Home component that renders the main page of the application.
 * It includes a navigation bar, hero cover, popular locations, results container, and footer.
 */
const Home = () => {
  const navigate = useNavigate();

  // State variables
  const [isDatePickerVisible, setisDatePickerVisible] = useState(false);
  const [locationInputValue, setLocationInputValue] = useState('');
  const [numGuestsInputValue, setNumGuestsInputValue] = useState('');
  const [popularDestinationsData, setPopularDestinationsData] = useState({
    isLoading: true,
    data: [],
    errors: [],
  });
  const [hotelsResults, setHotelsResults] = useState({
    isLoading: true,
    data: [],
    errors: [],
  });
  let availableHotelResponse = null;
  // State for storing available cities
  const [availableCities, setAvailableCities] = useState([]);

  const [availableHotel, setavailableHotel] = useState([]);

  const [filteredTypeheadResults, setFilteredTypeheadResults] = useState([]);

  //State for managing selected hotel ID
  const [hotelSelectedID, setHotelSelectedID] = useState('0');
  const [hotelName, setHotelName] = useState('');

  //State for managing CheckinDate
  const [startDate, setStartDate] = useState('');
  //State for managing CheckinDate
  const [endDate, setEndDate] = useState('');

  const onHotelChangeInput = async (newValue) => {
    setHotelSelectedID(newValue);
  };

  const onHotelNameChange = async (newValue) => {
    setHotelName(newValue);
  };

  const onStartDateChangeInput = async (newValue) => {
    setStartDate(newValue);
  };
  const onEndDateChangeInput = async (newValue) => {
    setEndDate(newValue);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_debounce(queryResults, 1000), []);

  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);

  const onDatePickerIconClick = () => {
    setisDatePickerVisible(!isDatePickerVisible);
  };

  const onLocationChangeInput = async (newValue) => {
    setLocationInputValue(newValue);
    // Debounce the queryResults function to avoid making too many requests
    debounceFn(newValue, availableCities);
  };

  /**
   * Queries the available cities based on the user's input.
   * @param {string} query - The user's input.
   * @returns {void}
   *
   */
  function queryResults(query, availableCities) {
    const filteredResults = availableCities.filter((city) =>
      city.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredTypeheadResults(filteredResults);
  }

  const onNumGuestsInputChange = (numGuests) => {
    if (
      (numGuests < MAX_GUESTS_INPUT_VALUE && numGuests > 0) ||
      numGuests === ''
    ) {
      setNumGuestsInputValue(numGuests);
    }
  };

  const onDateChangeHandler = (ranges) => {
    setDateRange([ranges.selection]);
  };

  /**
   * Handles the click event of the search button.
   * It gathers the number of guests, check-in and check-out dates, and selected city
   * from the component's state, and then navigates to the '/hotels' route with this data.
   */
  const onSearchButtonAction = () => {
    const activeFilters = getActiveFilters();
    const codeHotel = hotelSelectedID;
    const numGuest = Number(numGuestsInputValue);
    const checkInDate = formatDate(dateRange.startDate) ?? '';
    const checkOutDate = formatDate(dateRange.endDate) ?? '';

    const city = locationInputValue;

    navigate('/booking', {
      state: {
        numGuest,
        checkInDate,
        checkOutDate,
        city,
      },
    });
  };

  const getActiveFilters = () => {
    const filters = {};

    if (!isEmpty(filters)) {
      return filters;
    }
    return null;
  };

  async function fetchHotels() {
    try {
      const response = await axios.get(
        'http://mchapi.binix.online/api/GetHotel'
      ); // Update the URL to match your API

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching hotels:', error);
      throw error; // Re-throw the error to propagate it to the caller
    }
  }

  async function fetchHotelDetail() {
    try {
      const response = await axios.get(
        'http://mchapi.binix.online/api/HotelDetail?HotelCode=2'
      ); // Update the URL to match your API

      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.data;
    } catch (error) {
      console.error('Error fetching hotels:', error);
      throw error; // Re-throw the error to propagate it to the caller
    }
  }

  useEffect(() => {
    /**
     * Fetches initial data for the Home route.
     * @returns {Promise<void>} A promise that resolves when the data is fetched.
     */
    const getInitialData = async () => {
      const popularDestinationsResponse = await networkAdapter.get(
        '/api/popularDestinations'
      );

      // const hotelsResultsResponse = await networkAdapter.get('/api/nearbyHotels');

      const hotelsResultsResponse = await fetchHotelDetail();

      const availableCitiesResponse = await fetchHotels();
      if (availableCitiesResponse) {
        availableHotelResponse = availableCitiesResponse;
        setavailableHotel(availableCitiesResponse);
        let nameArray = availableCitiesResponse.map((item) => item.name);
        setAvailableCities(nameArray);
      }

      if (popularDestinationsResponse) {
        setPopularDestinationsData({
          isLoading: false,
          data: popularDestinationsResponse.data.elements,
          errors: popularDestinationsResponse.errors,
        });
      }
      if (hotelsResultsResponse) {
        setHotelsResults({
          isLoading: false,
          data: hotelsResultsResponse.data.elements,
          errors: hotelsResultsResponse.errors,
        });
      }
    };
    getInitialData();
  }, []);

  return (
    <>
      <HeroCover
        locationInputValue={locationInputValue}
        numGuestsInputValue={numGuestsInputValue}
        locationTypeheadResults={filteredTypeheadResults}
        isDatePickerVisible={isDatePickerVisible}
        setisDatePickerVisible={setisDatePickerVisible}
        onLocationChangeInput={onLocationChangeInput}
        hotelSelectedID={hotelSelectedID}
        onNumGuestsInputChange={onNumGuestsInputChange}
        onHotelChangeInput={onHotelChangeInput}
        onHotelNameChange={onHotelNameChange}
        dateRange={dateRange}
        onDateChangeHandler={onDateChangeHandler}
        onDatePickerIconClick={onDatePickerIconClick}
        onSearchButtonAction={onSearchButtonAction}
        autocompletesuggestion={availableHotel}
        onStartDateChangeInput={onStartDateChangeInput}
        onEndDateChangeInput={onEndDateChangeInput}
      />
      <div className="container mx-auto">
        {/* <PopularLocations popularDestinationsData={popularDestinationsData} /> */}
        <div className="my-8">
          <ResultsContainer
            hotelsResults={hotelsResults}
            enableFilters={false}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
