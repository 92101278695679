// utils/localforageWrapper.js
import localforage from 'localforage';
import getTabIdentifier from './tabIdentifier';

const tabIdentifier = getTabIdentifier();

const setItem = async (key, value) => {
  const namespacedKey = `${tabIdentifier}-${key}`;
  await localforage.setItem(namespacedKey, value);
};

const getItem = async (key) => {
  const namespacedKey = `${tabIdentifier}-${key}`;
  return await localforage.getItem(namespacedKey);
};

const removeItem = async (key) => {
  const namespacedKey = `${tabIdentifier}-${key}`;
  await localforage.removeItem(namespacedKey);
};

export default {
  setItem,
  getItem,
  removeItem,
};
