// src/apiService.js
import axios from 'axios';

const apiService = axios.create({
  baseURL: 'https://mchapi.binix.online/', // Replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getData = async (endpoint) => {
  try {
    const response = await apiService.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const postData = async (endpoint, data) => {
  try {
    const response = await apiService.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const post = async (endpoint, data) => {
  try {
    const API_URL = '';
    const endpointURL = new URL(endpoint, API_URL);
    const url = new URL(endpointURL, window.location.origin);
    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      credentials: 'include',
    });

    return await response.json();
  } catch (error) {
    return {
      data: {},
      errors: [error.message],
    };
  }
};

// Add more methods for other HTTP methods (PUT, DELETE, etc.) if needed
