import React, { useState, useEffect, useRef } from 'react';
import {
  faLocationDot,
  faPerson,
  faHouse,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import Input from 'components/ux/input/Input';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import 'bootstrap/dist/css/bootstrap.css';
import NumberInput from 'components/ux/inputspinner/inputspinner';
import Button from '@mui/material/Button';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import Loader from 'components/ux/loader/loader';
import { getData, postData } from 'components/Services/apiService';
import './Autocomplete.css';
import './CustomDatePicker.css';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { useStyles } from '../ux/style/bookingstyling';
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';
import { Hidden } from '@material-ui/core';

/**
 *
 * GlobalSearchBox Component
 * Renders a search box with input fields for location, number of guests, and a date range picker.
 * It includes a search button to trigger the search based on the entered criteria.
 *
 * @param {Object} props - Props for the component.
 * @param {string} props.locationInputValue - The current value of the location input.
 * @param {string} props.numGuestsInputValue - The current value of the number of guests input.
 * @param {boolean} props.isDatePickerVisible - Flag to control the visibility of the date picker.
 * @param {Function} props.onLocationChangeInput - Callback for location input changes.
 * @param {Function} props.onHotelChangeInput - Callback for Hotel Selected input changes.
 * @param {Function} props.onNumGuestsInputChange - Callback for number of guests input changes.
 * @param {Function} props.onDatePickerIconClick - Callback for the date picker icon click event.
 * @param {Array} props.locationTypeheadResults - Results for the location input typeahead.
 * @param {Function} props.onSearchButtonAction - Callback for the search button click event.
 * @param {Function} props.onDateChangeHandler - Callback for handling date range changes.
 * @param {Function} props.setisDatePickerVisible - Callback to set the visibility state of the date picker.
 * @param {Object} props.dateRange - The selected date range.
 * @param {Function} props.onStartDateChangeInput - Checkin Date
 * @param {Function} props.onEndDateChangeInput - Check-Out Date
 */
const GlobalSearchBox = (props) => {
  const numberInputRef = useRef(null);
  const [properties, setProperties] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null); // State for selected item

  const {
    locationInputValue,
    numGuestsInputValue,
    numRoomsInputValue = 1,
    onLocationChangeInput,
    onNumGuestsInputChange,
    locationTypeheadResults,
    onSearchButtonAction,
    onautocompletesuggestion,
    onHotelChangeInput,
    onHotelNameChange,
    onStartDateChangeInput,
    onEndDateChangeInput,
  } = props;

  const classes = useStyles();
  const { RangePicker } = DatePicker;
  const [open, setOpen] = useState(false);
  const [numRooms, setNumRooms] = useState(1);
  const [selectedStartDate, setSelectedStartDate] = useState(dayjs());
  const [selectedEndDate, setSelectedEndDate] = useState(
    dayjs().add(1, 'days')
  );
  const [selectedHotelId, setSelectedHotelId] = useState('0');
  const [loading, setLoading] = useState(false); // State for loading indicator

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleNumRoomsChange = (newValue) => {
    setNumRooms(newValue);
  };

  const onChange = (dates, dateStrings) => {
    const currentDate = moment();
    const startDate = dates ? dates[0] : currentDate;
    const endDate = dates ? dates[1] : currentDate;

    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);

    onStartDateChangeInput(startDate.format('DD-MMM-YYYY'));
    onEndDateChangeInput(endDate.format('DD-MMM-YYYY'));
  };

  const disabledDate = (current) => {
    const fromToday = current && current < dayjs().startOf('day');
    const isMoreThan7Days = false;
    return fromToday || isMoreThan7Days;
  };

  useEffect(() => {
    onStartDateChangeInput(selectedStartDate.format('DD-MMM-YYYY'));
    onEndDateChangeInput(selectedEndDate.format('DD-MMM-YYYY'));

    const fetchProperties = async () => {
      try {
        const data = await getData('/api/GetHotel'); // Replace with your API endpoint
        setProperties(data);

        if (Array.isArray(data)) {
          setSelectedItem(data[0]);
          console.log(selectedItem);
          onHotelChangeInput(data[0].id); // Update hotel input with default value
          setSelectedHotelId(data[0].id); // Update hotel ID with default value
        }
      } catch (error) {
        console.error('Error fetching Properties data:', error);
      }
    };

    fetchProperties();
  }, []);

  useEffect(() => {
    // Trigger search when selectedItem changes
    if (selectedItem) {
      handleSearchButtonClick();
    }
  }, [selectedItem]); // Depend on selectedItem change

  const onAutocompleteSelect = (event, selectedItem) => {
    if (selectedItem) {
      setSelectedItem(selectedItem); // Update selected item state
      onHotelChangeInput(selectedItem.id);
      setSelectedHotelId(selectedItem.id); // Update state with selected hotel ID
    } else {
      setSelectedItem(null); // Clear selected item state if no item selected
      //onHotelNameChange('');
      //onHotelChangeInput('0');
      //setSelectedHotelId('0');
    }
    //setSelectedHotel(selectedItem); // Update state with selected hotel data
  };

  const handleDatePickerFocus = (e) => {
    e.preventDefault(); // Prevent the default focus behavior
  };

  const handleSearchButtonClick = async () => {
    if (!selectedItem) {
      setOpen(true);
      return;
    }
    setLoading(true); // Set loading state to true when search button is clicked
    try {
      onHotelNameChange(selectedItem.name); // Adjust selectedItem as per your logic
      onHotelChangeInput(selectedItem.id); // Adjust selectedItem as per your logic
      // Perform your search action
      await onSearchButtonAction(
        selectedStartDate.format('DD-MMM-YYYY'),
        selectedEndDate.format('DD-MMM-YYYY'),
        selectedHotelId,
        numRooms
      );

      // After search action completes, you can set loading back to false
    } catch (error) {
      console.error('Error performing search:', error);
    } finally {
      setLoading(false); // Set loading state back to false after search completes (or fails)
    }
  };

  return (
    <div className="flex flex-wrap flex-col lg:flex-row hero-content__search-box">
      <div style={{ margin: 5 }}>
        <Autocomplete
          id="property"
          value={selectedItem}
          options={properties}
          onChange={onAutocompleteSelect}
          getOptionLabel={(option) => option.name}
          fullWidth
          PaperComponent={({ children }) => (
            <Paper style={{ background: '#f5f5f5' }}>{children}</Paper>
          )}
          style={{ minWidth: 300, height: 45, margin: 0 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Property"
              variant="outlined"
              style={{ backgroundColor: 'white !important', height: 45 }}
              InputProps={{
                ...params.InputProps,
                style: { height: 45, padding: 0 },
              }}
              inputProps={{
                ...params.inputProps,
                style: { height: 40, padding: '0 14px' },
              }}
            />
          )}
          required
        />
      </div>
      <div style={{ margin: 0, marginTop: 0 }}>
        <DatePicker.RangePicker
          defaultValue={[selectedStartDate, selectedEndDate]}
          placeholder={['Check-In', 'Check-Out']}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'DD-MM-YYYY'}
          inputReadOnly
          onFocus={handleDatePickerFocus}
          style={{ minWidth: 300, height: 45, margin: 5 }}
        />
      </div>
      <div style={{ margin: 5 }}>
        <div
          className="flex"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <label
            htmlFor="rooms"
            style={{
              color: 'black',
              marginRight: 'auto',
            }}
          >
            Rooms
          </label>
          <NumberInput
            id="rooms"
            ref={numberInputRef}
            aria-label="Quantity Input"
            min={1}
            max={10}
            style={{ marginLeft: 'auto' }}
            onChange={handleNumRoomsChange}
          />
        </div>
      </div>

      <div style={{ margin: 5, display: 'none' }}>
        <div
          className="flex"
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <label
            htmlFor="adults"
            style={{
              color: 'black',
              marginRight: 'auto',
            }}
          >
            Adult
          </label>
          <NumberInput
            id="adults"
            ref={numberInputRef}
            aria-label="Quantity Input"
            min={1}
            max={10}
            style={{ marginLeft: 'auto' }}
          />
        </div>
      </div>
      <div style={{ margin: 5 }}>
        <Button
          className={classes.searchbutton}
          variant="outlined"
          startIcon={<SearchIcon />}
          onClick={handleSearchButtonClick}
        >
          Search
        </Button>
        {loading && (
          <Loader isFullScreen={true} loaderText={'Loading Room List.....!'} />
        )}
      </div>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Please select Property / Dates...!!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default GlobalSearchBox;
