import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Container, Grid, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { formatPrice } from 'utils/price-helpers';
import PrintSharpIcon from '@mui/icons-material/PrintSharp';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';

/**
 * Represents the booking confirmation component.
 * @component
 * @returns {JSX.Element} The booking confirmation component.
 */
const BookingConfirmation = () => {
  const contentToPrint = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [bookingDetails, setBookingDetails] = useState(null);

  const terms = `Policy of Reservation & Cancellation :-
1. Individual/FIT Booking:-
A full deposit for the stay will be charged for confirmed booking.

2. Cancellation :-We do not accept Cancellation over Phone : Cancellation can be done on any working day between 10:00 Hours to 18:00 hours from Monday to Saturday (except National Holiday). Booking can be cancelled & amendment before 07 days from date of booking, Booking can not cancelled, No refund, No amendment in Black out dates.

3. Amendment can be done before 07 days & difference Charges will be given as per actual rates.

4. Age Policy :-Extra above 4 yrs -9yrs Child  Rs.1500 +Taxes Per Kid Per Night basis. (4 Adult in one room not allowed)

5. Refund Policy :- Refund of Advance will be made within 30 days of the cancellation as per the cancellation policy.

6. Confirmation policy:- Booking will be treated as confirmed only against 100% payment. No Cancellation-No Refund-No Amendment Black out period:- Diwali Period 29th Oct 2024 to 12th Nov 2024 & New Year period 19th Dec,2024 to 10th Jan,2025

7. Full deposit for the group booking more then 05 rooms will be charged for confirm booking, No Cancellation, No Refund, No date Amendment for group booking.

Terms & Conditions

(i)Our check “in time is 1500 hrs. and check out time is 1200hrs.Any early check-in or late check-out will be subject to availability and will be charged accordingly.

(ii)As per RBI guidelines, it will be mandatory to enter ATM PIN while using your credit card or debit card at our hotels.

(iii)As per recent Govt. law, it is Mandatory for all the guests to show their Photo ID proof at the time of Check-In which can be in form of Passport and Visa Copy, Driving License, Voter ID Card, failing which the hotel reserves the right of refusal. IN CASE OF foreigner guest passport with Visa is mandatory. Pan Card will not be accepted.`;

  /**
   * Handles the print event.
   * @function
   * @returns {void}
   */
  const handlePrint = useReactToPrint({
    documentTitle: 'Booking Confirmation',
    removeAfterPrint: true,
    content: () => contentToPrint.current,
  });

  // Set booking details from location state passed from the previous page(checkout page)
  useEffect(() => {
    if (location.state) {
      const { bookingDetails } = location.state.confirmationData;
      setBookingDetails(bookingDetails);
    } else {
      navigate('/');
    }
  }, [location.state, navigate]);

  const StyledSpan = styled('span')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 13,
    },
    paddingTop: 0,
  }));

  const StyledSpanBold = styled('span')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      fontWeight: 600,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      fontWeight: 600,
    },
    paddingTop: 0,
  }));

  return (
    <div className="md:mx-auto max-w-[800px] my-1">
      <div className="flex justify-between mx-2 rounded-md my-2">
        <Link
          to="/"
          className="border p-2 min-w-[120px] transition-all hover:bg-blue-500 hover:text-white"
          style={{ textDecoration: 'none' }}
        >
          <HomeSharpIcon></HomeSharpIcon>
          Book Again
        </Link>
        <button
          onClick={handlePrint}
          className="border p-2 min-w-[120px] transition-all hover:bg-blue-500 hover:text-white"
        >
          <PrintSharpIcon></PrintSharpIcon>
          Print Voucher
        </button>
      </div>
      <div
        ref={contentToPrint}
        className="flex mx-2 px-1 py-1 justify-center flex-col border rounded-md"
      >
        <Box
          bgcolor="grey"
          color="white"
          textAlign="center"
          p={0.5}
          fontWeight="normal"
          mb={2}
        >
          <Typography variant="h6" fontWeight="bold">
            Hotel Booking Voucher
          </Typography>
        </Box>
        <Box mb={2}>
          <StyledSpan>Dear Sir/Madam,</StyledSpan>
          <p></p>
          <StyledSpan>
            Thank you for choosing HOTEL NAME for your stay. We are pleased to
            inform the status of your reservation as under:
          </StyledSpan>
        </Box>
        <Grid container spacing={1} mb={1}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={0}>
              <Grid item xs={5} sm={4} sx={{ paddingTop: 0 }}>
                <StyledSpan>Name of Guest</StyledSpan>
              </Grid>
              <Grid item xs={7} sm={8} sx={{ paddingTop: 0 }}>
                <StyledSpanBold>Mr Gautam Sharma</StyledSpanBold>
              </Grid>
              <Grid item xs={5} sm={4} sx={{ paddingTop: 0 }}>
                <StyledSpan>Mobile No</StyledSpan>
              </Grid>
              <Grid item xs={7} sm={8} sx={{ paddingTop: 0 }}>
                <StyledSpanBold>+919898989</StyledSpanBold>
              </Grid>
              <Grid item xs={5} sm={4} sx={{ paddingTop: 0 }}>
                <StyledSpan>Arrival</StyledSpan>
              </Grid>
              <Grid item xs={7} sm={8} sx={{ paddingTop: 0 }}>
                <StyledSpanBold>17-Jul-2024</StyledSpanBold>
              </Grid>
              <Grid item xs={5} sm={4} sx={{ paddingTop: 0 }}>
                <StyledSpan>Departure</StyledSpan>
              </Grid>
              <Grid item xs={7} sm={8} sx={{ paddingTop: 0 }}>
                <StyledSpanBold>17-Jul-2024</StyledSpanBold>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={0}>
              <Grid item xs={7} sm={8} sx={{ paddingTop: 0 }}>
                <StyledSpan>Receipt No</StyledSpan>
              </Grid>
              <Grid item xs={5} sm={4} sx={{ paddingTop: 0 }}>
                <StyledSpanBold>1252</StyledSpanBold>
              </Grid>
              <Grid item xs={7} sm={8} sx={{ paddingTop: 0 }}>
                <StyledSpan>Received Amount</StyledSpan>
              </Grid>
              <Grid item xs={5} sm={4} sx={{ paddingTop: 0 }}>
                <StyledSpanBold>1500.00</StyledSpanBold>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Paper elevation={3} style={{ marginBottom: 20 }}>
          <Box
            bgcolor="#D0D3D4"
            color="black"
            textAlign="center"
            p={0.5}
            fontWeight="normal"
          >
            HOTEL NAME
          </Box>
          <Grid container spacing={0} p={0.5}>
            <Grid item xs={12} sm={2}>
              <Box
                display="flex"
                flexDirection={{ xs: 'row', sm: 'column' }}
                alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
              >
                <Box
                  flex={1}
                  textAlign={{ xs: 'left', sm: 'left' }}
                  minHeight={{ xs: 20, sm: 45 }}
                >
                  <StyledSpan>Arrival Date</StyledSpan>
                </Box>
                <Box flex={1} textAlign={{ xs: 'left', sm: 'left' }}>
                  <StyledSpanBold>18-Jul-24</StyledSpanBold>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.5}>
              <Box
                display="flex"
                flexDirection={{ xs: 'row', sm: 'column' }}
                alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
              >
                <Box
                  flex={1}
                  textAlign={{ xs: 'left', sm: 'left' }}
                  minHeight={{ xs: 20, sm: 45 }}
                >
                  <StyledSpan> Departure Date</StyledSpan>
                </Box>
                <Box flex={1} textAlign={{ xs: 'left', sm: 'center' }}>
                  <StyledSpanBold>19-Jul-24</StyledSpanBold>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={1}>
              <Box
                display="flex"
                flexDirection={{ xs: 'row', sm: 'column' }}
                alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
              >
                <Box
                  flex={1}
                  textAlign={{ xs: 'left', sm: 'left' }}
                  minHeight={{ xs: 20, sm: 45 }}
                >
                  <StyledSpan>No. of Rooms</StyledSpan>
                </Box>
                <Box flex={1} textAlign={{ xs: 'left', sm: 'center' }}>
                  <StyledSpanBold>1</StyledSpanBold>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box
                display="flex"
                flexDirection={{ xs: 'row', sm: 'column' }}
                alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
              >
                <Box
                  flex={1}
                  textAlign={{ xs: 'left', sm: 'center' }}
                  minHeight={{ xs: 20, sm: 45 }}
                >
                  <StyledSpan>Room Type</StyledSpan>
                </Box>
                <Box flex={1} textAlign={{ xs: 'left', sm: 'center' }}>
                  <StyledSpanBold>Deluxe Room</StyledSpanBold>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={1}>
              <Box
                display="flex"
                flexDirection={{ xs: 'row', sm: 'column' }}
                alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
              >
                <Box
                  flex={1}
                  textAlign={{ xs: 'left', sm: 'left' }}
                  minHeight={{ xs: 20, sm: 45 }}
                >
                  <StyledSpan>Tariff Per Night</StyledSpan>
                </Box>
                <Box flex={1} textAlign={{ xs: 'left', sm: 'left' }}>
                  <span
                    style={{
                      color: 'black',
                      fontWeight: 500,
                      fontSize: 14,
                      textAlign: 'right',
                      fontFamily: 'Poppins, sans-serif',
                    }}
                  >
                    ₹
                  </span>
                  <StyledSpanBold>{formatPrice(5000)}</StyledSpanBold>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={1.5}>
              <Box
                display="flex"
                flexDirection={{ xs: 'row', sm: 'column' }}
                alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
              >
                <Box
                  flex={1}
                  textAlign={{ xs: 'left', sm: 'center' }}
                  minHeight={{ xs: 20, sm: 45 }}
                >
                  <StyledSpan>Occupancy</StyledSpan>
                </Box>
                <Box flex={1} textAlign={{ xs: 'left', sm: 'center' }}>
                  <StyledSpanBold>Single</StyledSpanBold>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={1}>
              <Box
                display="flex"
                flexDirection={{ xs: 'row', sm: 'column' }}
                alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
              >
                <Box
                  flex={1}
                  textAlign={{ xs: 'left', sm: 'center' }}
                  minHeight={{ xs: 20, sm: 45 }}
                >
                  <StyledSpan>Plan</StyledSpan>
                </Box>
                <Box flex={1} textAlign={{ xs: 'left', sm: 'center' }}>
                  <StyledSpanBold>CP</StyledSpanBold>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={1}>
              <Box
                display="flex"
                flexDirection={{ xs: 'row', sm: 'column' }}
                alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
              >
                <Box
                  flex={1}
                  textAlign={{ xs: 'left', sm: 'center' }}
                  minHeight={{ xs: 20, sm: 45 }}
                >
                  <StyledSpan>Adult</StyledSpan>
                </Box>
                <Box flex={1} textAlign={{ xs: 'left', sm: 'center' }}>
                  <StyledSpanBold>1</StyledSpanBold>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={1} p={2}>
            <Grid item xs={6} sm={3}>
              <span variant="body2">Child: 0</span>
            </Grid>
            <Grid item xs={6} sm={3}>
              <span variant="body2">Infant: 0</span>
            </Grid>
            <Grid item xs={6} sm={3}>
              <span variant="body2">Extra Pax Charge: 0.00</span>
            </Grid>
            <Grid item xs={6} sm={3}>
              <span variant="body2">P. Charge: 0.00</span>
            </Grid>
          </Grid>
        </Paper>
        <Box mb={4}>
          <StyledSpanBold>Remarks :</StyledSpanBold>
          <p></p>
          <StyledSpan>
            Thanking you once again and assuring you of our best attentions at
            all times,
          </StyledSpan>
        </Box>
        <Box>
          <Typography variant="body1">Yours truly,</Typography>
          <StyledSpanBold>HOTEL NAME</StyledSpanBold>
        </Box>
        <div
          style={{
            color: 'black',
            fontWeight: 400,
            fontSize: 12,
            margin: 10,
            textAlign: 'justify',
            fontFamily: 'Poppins, sans-serif',
          }}
          dangerouslySetInnerHTML={{ __html: JSON.stringify(terms) }}
        />
        <div className="mt-4 flex justify-center flex-wrap items-center">
          {bookingDetails &&
            bookingDetails.map((detail, index) => (
              <div key={index} className="border-r-2 px-4">
                <p className="text-gray-600 text-sm">{detail.label}</p>
                <span className="text-gray-600 text-sm font-bold">
                  {detail.value}
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
