import React, { useEffect, useState, useRef } from 'react';
import FinalBookingSummary from './components/final-booking-summary/FinalBookingSummary';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getReadableMonthFormat } from 'utils/date-helpers';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import { networkAdapter } from 'services/NetworkAdapter';
import Loader from 'components/ux/loader/loader';
import Toast from 'components/ux/toast/Toast';
import localforageWrapper from '../../utils/localforageWrapper';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useStyles } from '../../components/ux/style/bookingstyling';
import SendIcon from '@mui/icons-material/Send';
import { Row } from 'antd';
import { Margin } from '@mui/icons-material';

/**
 * Checkout component for processing payments and collecting user information.
 *
 * @returns {JSX.Element} The rendered Checkout component.
 */
const Checkout = () => {
  const [errors, setErrors] = useState({});

  const location = useLocation();
  const bookingDetail = location.state;

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [toastMessage, setToastMessage] = useState('');

  const { isAuthenticated, userDetails } = useContext(AuthContext);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [paymentConfirmationDetails, setPaymentConfirmationDetails] = useState({
    isLoading: false,
    data: {},
  });

  const dismissToast = () => {
    setToastMessage('');
  };

  const classes = useStyles();
  let checkInDate, checkOutDate, hotelName;
  // Form state for collecting user payment and address information
  const [formData, setFormData] = useState({
    title: 'Mr',
    firstname: '',
    lastname: '',
    mobileno: '',
    email: '',
    address: '',
    city: '',
    state: '',
    pinCode: '',
    remarks: '',
  });

  const titleRef = useRef(null);
  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);
  const mobilenoRef = useRef(null);
  const emailRef = useRef(null);
  const addressRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const pinCodeRef = useRef(null);
  const remarksRef = useRef(null);

  const refs = {
    title: titleRef,
    firstname: firstnameRef,
    lastname: lastnameRef,
    mobileno: mobilenoRef,
    email: emailRef,
    address: addressRef,
    city: cityRef,
    state: stateRef,
    pinCode: pinCodeRef,
    remarks: remarksRef,
  };

  const scrollToError = () => {
    debugger;
    for (const key in errors) {
      if (errors[key] && refs[key].current) {
        refs[key].current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        break;
      }
    }
  };
  // Format the check-in and check-out date and time
  const checkInDateTime = `${getReadableMonthFormat(
    searchParams.get('checkIn')
  )}, ${location.state?.checkInTime}`;
  const checkOutDateTime = `${getReadableMonthFormat(
    searchParams.get('checkOut')
  )}, ${location.state?.checkOutTime}`;

  const [dataSummary, setDataSummary] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    fetchData();
    hotelName = 'ssss';
    const locationState = location.state;
    const checkIn = searchParams.get('checkIn');
    const checkOut = searchParams.get('checkOut');
    if (!locationState || !checkIn || !checkOut) {
      //const hotelCode = searchParams.get('hotelCode');
      //navigate(`/hotel/${hotelCode}`);
    }
  }, [location, navigate, searchParams]);

  const fetchData = async () => {
    try {
      setDataSummary([]);
      const value = await localforageWrapper.getItem('myData');
      if (Array.isArray(value)) {
        const summary = value.reduce((acc, node) => {
          // Skip nodes that don't have the properties needed for the summary
          if (
            !node.hotelcode ||
            !node.rtcode ||
            !node.ratecode ||
            !node.ocode
          ) {
            return acc;
          }

          // Create a unique key for grouping
          const key = `${node.hotelcode}-${node.rtcode}-${node.ratecode}-${node.ocode}`;

          // If the group doesn't exist, create it
          if (!acc[key]) {
            acc[key] = {
              srno: node.srno,
              hotelcode: node.hotelcode,
              rtcode: node.rtcode,
              ratecode: node.ratecode,
              ocode: node.ocode,
              roomtype: node.roomtype,
              adult: 0,
              child: 0,
              adultrate: 0,
              extraadultrate: 0,
              childrate: 0,
              childage: node.childage, // Assuming childage is the same for the same group
              totalroom: 0,
              totalcount: 0, // Initialize totalcount
              totalamount: 0, // Initialize totalcount
            };
          }

          // Sum the values
          acc[key].adult += node.adult;
          acc[key].child += node.child;
          acc[key].adultrate += node.adultrate;
          acc[key].extraadultrate += node.extraadultrate;
          acc[key].childrate += node.childrate * node.child;
          acc[key].totalroom += node.totalroom;
          acc[key].totalcount += 1; // Increment totalcount
          acc[key].totalamount += node.adultrate + node.childrate * node.child; // Increment totalcount

          return acc;
        }, {});

        // Convert the grouped object back to an array
        const summaryArray = Object.values(summary);

        setDataSummary(summaryArray);
        if (Array.isArray(summaryArray)) {
          // Sum up the totalamount from each object in summaryArray
          const totalAmount = summaryArray.reduce(
            (sum, item) => sum + item.totalamount,
            0
          );
          setTotalAmount(totalAmount);
        }
      }
    } catch (error) {
      console.error('Error retrieving data:', error);
    }
  };
  /**
   * Handle form input changes and validate the input.
   * @param {React.ChangeEvent<HTMLInputElement>} e The input change event.
   */
  // Handle change in input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate the field
    const isFieldValid = validationSchema[name](value);
    setErrors({ ...errors, [name]: !isFieldValid });

    // Check if the form is valid
    let isValid = true;
    Object.keys(formData).forEach((field) => {
      if (field === name) {
        isValid = isValid && isFieldValid;
      } else {
        isValid = isValid && validationSchema[field](formData[field]);
      }
    });
    //setIsSubmitDisabled(!isValid);
  };

  const handleSubmitWithScroll = (e) => {
    handleSubmit(e);
    scrollToError();
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    // Perform final validation before submission
    let isValid = true;
    const newErrors = {};
    Object.keys(formData).forEach((field) => {
      const isFieldValid = validationSchema[field](formData[field]);
      newErrors[field] = !isFieldValid;
      isValid = isValid && isFieldValid;
    });

    setErrors(newErrors);

    if (isValid) {
      // Submit form data
      console.log('Form submitted:', formData);

      // Retrieve existing data from localforage
      let existingData = await localforageWrapper.getItem('myData');
      if (!Array.isArray(existingData)) {
        existingData = [];
      }

      // Check if the entry with the same key exists
      const existingIndex = existingData.findIndex(
        (item) => item.key === formData.key
      );

      if (existingIndex !== -1) {
        // Update existing entry
        existingData[existingIndex] = formData;
      } else {
        // Add new formData to existing data
        existingData.push(formData);
      }

      // Store the updated data back to localforage
      await localforageWrapper.setItem('myData', existingData);
      console.log('Form submitted:', JSON.stringify(existingData));

      const response = await networkAdapter.post(
        '/api/payments/confirmation',
        formData
      );
      navigate(`/bookingconfirmation?voucherno=123`, {
        state: {
          confirmationData: response.data,
        },
      });
    } else {
      console.log('Validation failed');
    }
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col md:flex-row gap-y-0 w-full">
        <div className="md:order-2 bg-white-200 p-2">
          <div
            className={`shadow-lg border full min-w-[300px] bg-white md:block md:static md:shadow-none `}
            style={{ margin: 0, minHeight: '365px' }}
          >
            <div
              className="booking-details__header flex justify-between items-center py-2 border-b-2 border border-gray-300 px-2"
              style={{ backgroundColor: '#f8f8f8' }}
            >
              <span
                style={{
                  color: 'black',
                  fontWeight: 600,
                  fontSize: 14,
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
                Booking Details
              </span>
            </div>
            <div className="d-flex justify-content-between px-2  p-1 ">
              <span
                style={{
                  color: '#1e87f0',
                  fontWeight: 400,
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
                {bookingDetail.hotelName}
              </span>
            </div>
            <div className="d-flex justify-content-between border-b border-gray-300 mb-3 px-2 p-1">
              <div>
                <div style={{ color: '#878787', fontSize: '12px' }}>
                  Check-In
                </div>
                <div
                  style={{
                    color: 'black',
                    fontWeight: 600,
                    fontSize: 14,
                    fontFamily: 'Poppins, sans-serif',
                  }}
                >
                  {bookingDetail.checkIn}
                </div>
              </div>
              <div>
                <div style={{ color: '#878787', fontSize: '12px' }}>
                  Check-Out
                </div>
                <div
                  style={{
                    color: 'black',
                    fontWeight: 600,
                    fontSize: 14,
                    fontFamily: 'Poppins, sans-serif',
                  }}
                >
                  {bookingDetail.checkOut}
                </div>
              </div>
            </div>
            {dataSummary.length > 0 ? (
              <>
                {dataSummary.map((node) => (
                  <div
                    className="border-b"
                    key={node.srno}
                    style={{ margin: 0 }}
                  >
                    <div className="flex justify-between items-center px-2">
                      <span
                        style={{
                          color: 'black',
                          fontWeight: 600,
                          fontSize: 14,
                          fontFamily: 'Poppins, sans-serif',
                        }}
                      >
                        <p>{node.roomtype}</p>
                      </span>
                    </div>

                    <div className="px-2">
                      <div className="d-flex justify-content-between mb-3 px-2">
                        <div>
                          <div style={{ color: '#878787', fontSize: '12px' }}>
                            Rooms
                          </div>
                          <div
                            style={{
                              color: 'black',
                              fontWeight: 500,
                              fontSize: 14,
                              fontFamily: 'Poppins, sans-serif',
                            }}
                          >
                            {node.totalroom}
                          </div>
                        </div>
                        <div>
                          <div style={{ color: '#878787', fontSize: '12px' }}>
                            Adult
                          </div>
                          <div
                            style={{
                              color: 'black',
                              fontWeight: 500,
                              fontSize: 14,
                              fontFamily: 'Poppins, sans-serif',
                            }}
                          >
                            {node.adult}
                          </div>
                        </div>
                        <div>
                          <div style={{ color: '#878787', fontSize: '12px' }}>
                            Child
                          </div>
                          <div
                            style={{
                              color: 'black',
                              fontWeight: 500,
                              fontSize: 14,
                              fontFamily: 'Poppins, sans-serif',
                            }}
                          >
                            {node.child}
                          </div>
                        </div>
                        <div>
                          <p></p>
                          <p
                            style={{
                              color: 'black',
                              fontWeight: 500,
                              fontSize: 14,
                              fontFamily: 'Poppins, sans-serif',
                            }}
                          >
                            ₹ {node.totalamount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="d-flex border-b justify-content-between m-2 px-2">
                  <span>Total</span>
                  <span
                    style={{
                      color: 'black',
                      fontWeight: 600,
                      fontSize: 16,
                      fontFamily: 'Poppins, sans-serif',
                    }}
                  >
                    ₹ {totalAmount}
                  </span>
                </div>
              </>
            ) : (
              <div className="px-2">No Room Selected.....!</div>
            )}
          </div>
        </div>
        <div className="md:order-1 bg-white-200 p-2">
          <div
            className={`shadow-lg border full min-w-[300px] bg-white md:block md:static md:shadow-none `}
            style={{ margin: 0, minHeight: '365px' }}
          >
            <div
              className="booking-details__header flex justify-between items-center py-2 border-b-2 border border-gray-300 px-2"
              style={{ backgroundColor: '#f8f8f8', marginBottom: 10 }}
            >
              <span
                style={{
                  color: 'black',
                  fontWeight: 600,
                  fontSize: 14,
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
                Guest Details
              </span>
            </div>
            <Grid container spacing={1}>
              {/* Title */}
              <Grid item xs={12} sm={2}>
                <Select
                  style={{
                    height: '40px',
                    width: '95%',
                    margin: 5,
                    maxWidth: 150,
                    fontFamily: 'Poppins, sans-serif',
                  }}
                  ref={refs.title}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={formData.title}
                  name="title"
                  onChange={handleChange}
                >
                  <MenuItem value={'Mr'}>Mr</MenuItem>
                  <MenuItem value={'Mrs'}>Mrs</MenuItem>
                  <MenuItem value={'Ms'}>Ms</MenuItem>
                  <MenuItem value={'Dr'}>Dr</MenuItem>
                </Select>
              </Grid>

              {/* First Name */}
              <Grid item xs={12} sm={5}>
                <TextField
                  style={{
                    color: '#1e87f0',
                    fontWeight: 400,
                    width: '100%',
                    padding: 5,
                    fontFamily: 'Poppins, sans-serif',
                  }}
                  InputProps={{
                    style: {
                      height: '40px',
                      padding: '0',
                    },
                  }}
                  ref={refs.firstname}
                  required
                  label="First Name"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleChange}
                  error={errors.firstname}
                  helperText={errors.firstname ? 'First name is required' : ''}
                />
              </Grid>

              {/* Last Name */}
              <Grid item xs={12} sm={5}>
                <TextField
                  style={{
                    color: '#1e87f0',
                    fontWeight: 400,
                    width: '100%',
                    padding: 5,
                    fontFamily: 'Poppins, sans-serif',
                  }}
                  InputProps={{
                    style: {
                      height: '40px',
                      padding: '0',
                    },
                  }}
                  ref={refs.lastname}
                  label="Last Name"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleChange}
                  error={errors.lastname}
                  helperText={errors.lastname ? 'Last name is required' : ''}
                />
              </Grid>

              {/* Mobile No */}
              <Grid item xs={12} sm={6}>
                <TextField
                  style={{
                    color: '#1e87f0',
                    fontWeight: 400,
                    width: '100%',
                    padding: 5,
                    fontFamily: 'Poppins, sans-serif',
                  }}
                  InputProps={{
                    style: {
                      height: '40px',
                      padding: '0',
                    },
                  }}
                  ref={refs.mobileno}
                  required
                  label="Mobile No"
                  name="mobileno"
                  value={formData.mobileno}
                  onChange={handleChange}
                  error={errors.mobileno}
                  helperText={
                    errors.mobileno
                      ? 'Enter a valid 10-digit mobile number'
                      : ''
                  }
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} sm={6}>
                <TextField
                  style={{
                    color: '#1e87f0',
                    fontWeight: 400,
                    width: '100%',
                    padding: 5,
                    fontFamily: 'Poppins, sans-serif',
                  }}
                  InputProps={{
                    style: {
                      height: '40px',
                      padding: '0',
                    },
                  }}
                  ref={refs.email}
                  required
                  label="Email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={errors.email}
                  helperText={errors.email ? 'Enter a valid email' : ''}
                />
              </Grid>

              {/* Address */}
              <Grid item xs={12} sm={6}>
                <TextField
                  style={{
                    color: '#1e87f0',
                    fontWeight: 400,
                    width: '100%',
                    padding: 5,
                    fontFamily: 'Poppins, sans-serif',
                  }}
                  InputProps={{
                    style: {
                      height: '40px',
                      padding: '0',
                    },
                  }}
                  ref={refs.address}
                  label="Address"
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  error={errors.address}
                  helperText={errors.address ? 'Address is required' : ''}
                />
              </Grid>

              {/* City */}
              <Grid item xs={12} sm={6}>
                <TextField
                  style={{
                    color: '#1e87f0',
                    fontWeight: 400,
                    width: '100%',
                    padding: 5,
                    fontFamily: 'Poppins, sans-serif',
                  }}
                  InputProps={{
                    style: {
                      height: '40px',
                      padding: '0',
                    },
                  }}
                  ref={refs.city}
                  label="City"
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  error={errors.city}
                  helperText={errors.city ? 'City is required' : ''}
                />
              </Grid>

              {/* State */}
              <Grid item xs={12} sm={6}>
                <TextField
                  style={{
                    color: '#1e87f0',
                    fontWeight: 400,
                    width: '100%',
                    padding: 5,
                    fontFamily: 'Poppins, sans-serif',
                  }}
                  InputProps={{
                    style: {
                      height: '40px',
                      padding: '0',
                    },
                  }}
                  ref={refs.state}
                  label="State"
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  error={errors.state}
                  helperText={errors.state ? 'State is required' : ''}
                />
              </Grid>

              {/* Pin code */}
              <Grid item xs={12} sm={6}>
                <TextField
                  style={{
                    color: '#1e87f0',
                    fontWeight: 400,
                    width: '100%',
                    padding: 5,
                    fontFamily: 'Poppins, sans-serif',
                  }}
                  InputProps={{
                    style: {
                      height: '40px',
                      padding: '0',
                    },
                  }}
                  ref={refs.pinCode}
                  label="Pin code"
                  type="text"
                  name="pinCode"
                  value={formData.pinCode}
                  onChange={handleChange}
                  error={errors.pinCode}
                  helperText={
                    errors.pinCode ? 'Enter a valid 6-digit pin code' : ''
                  }
                />
              </Grid>

              {/* Special Remarks  */}
              <Grid item xs={12} sm={8}>
                <TextField
                  style={{
                    color: '#1e87f0',
                    fontWeight: 400,
                    width: '100%',
                    padding: 5,
                    fontFamily: 'Poppins, sans-serif',
                  }}
                  InputProps={{
                    style: {
                      height: '40px',
                      padding: '0',
                    },
                  }}
                  label="Remarks / Requests"
                  type="text"
                  name="remarks"
                  value={formData.remarks}
                  onChange={handleChange}
                  error={errors.remarks}
                />
              </Grid>
              {/* Pay Now button */}
              <Grid item xs={12} sm={4}>
                <div className="text-center my-1">
                  {dataSummary.length > 0 ? (
                    <Button
                      className={classes.paybutton}
                      variant="outlined"
                      onClick={handleSubmitWithScroll}
                      disabled={isSubmitDisabled}
                    >
                      Pay Now <strong>&nbsp;&nbsp;₹ {totalAmount}</strong>
                    </Button>
                  ) : (
                    'Please Select Rooms....!'
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="text-center my-1"></div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Generic Input field component for collecting user information.
 * @param {Object} props The component props.
 * @param {string} props.label The input field label.
 * @param {string} props.type The input field type.
 * @param {string} props.name The input field name.
 * @param {string} props.value The input field value.
 * @param {Function} props.onChange The input field change handler.
 * @param {string} props.placeholder The input field placeholder.
 * @param {boolean} props.required The input field required status.
 * @param {boolean} props.error The input field error status.
 *
 * @returns {JSX.Element} The rendered InputField component.
 */
const InputField = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  required,
  error,
}) => (
  <div className="mb-4">
    <label
      className="block text-gray-700 text-sm font-bold mb-2"
      htmlFor={name}
    >
      {label}
    </label>
    <input
      className={`shadow appearance-none border ${
        error ? 'border-red-500' : 'border-gray-300'
      } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
      id={name}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      aria-invalid={error ? 'true' : 'false'}
    />
    {error && (
      <p className="text-red-500 text-xs my-1">Please check this field.</p>
    )}
  </div>
);

// Validation schema for form fields
// Define the validation schema
const validationSchema = {
  title: (value) => value.length > 0,
  firstname: (value) => value.length > 0,
  lastname: (value) => value.length >= 0,
  mobileno: (value) => /^[0-9]{10}$/.test(value),
  mobilenoreq: (value) => /^[0-9]{10}$/.test(value),
  email: (value) => /\S+@\S+\.\S+/.test(value),
  address: (value) => value.length >= 0,
  city: (value) => value.length >= 0,
  state: (value) => value.length >= 0,
  pinCode: (value) => /^[0-9]{6}$/.test(value),
  remarks: (value) => value.length >= 0,
};

export default Checkout;
